<template>
  <base-timeline :items="ciblesWithLinkedObj" :color="color">
    <template slot-scope="entry">
      <base-card :actions="getActionsForItem(entry.item)" @menuClick="onMenuClick">
        <q-avatar slot='avatar' size="3rem" :text-color="color" icon="wifi_tethering" />

        <q-item-label caption>{{$formatDate(entry.item.date)}}</q-item-label>
        <q-item-label><strong>{{entry.item.label}}</strong></q-item-label>

        <div class="row">
          {{entry.linkedObjects}}
          <q-item v-for="obj in entry.item.linkedObjects" :key="obj.id" class="col col-auto">
            <q-item-section avatar>
              <q-avatar :icon="obj.icon.name" size="2rem" :color="obj.icon.color" />
            </q-item-section>
            <q-item-section>
              <q-item-label caption>{{obj.label}}</q-item-label>
            </q-item-section>
          </q-item>
        </div>

        <div slot="indicators" class="q-pa-md column justify-between">
          <q-icon :name="$getIcon('statut', entry.item.statut)" size="1.5rem" />
          <q-icon :name="$getIcon('qualification', entry.item.qualification)" size="1.5rem" />
        </div>
      </base-card>
    </template>
  </base-timeline>
</template>

<script>
export default {
  name: 'CiblesTimeline',
  props: {
    cibles: { type: Array, default: () => ([]) },
    color: { type: String }
  },

  computed: {
    ciblesWithLinkedObj () {
      return this.cibles.map(cible => {
        let linkedObjects = []

        cible.cibles.forEach(sousCible => {
          const related_objects = []
            .concat(sousCible.agents)
            .concat(sousCible.wallets)
            .concat(sousCible.entities)

          linkedObjects = linkedObjects.concat(related_objects.map(obj => ({
            label: this.linkedObjectLabel(obj),
            icon: this.linkedObjectIcon(obj)
          })))
        })

        return {
          ...cible, linkedObjects
        }
      })
    }
  },

  methods: {
    getActionsForItem (item) {
      return [
        { label: this.$t('cibles.edit_cible'), payload: { name: 'cible-show', params: { id: item.id } } }
      ]
    },

    onMenuClick ({ name, params }) {
      this.$router.push({ name, params })
    },

    linkedObjectType (obj) {
      if (obj['NOMITV']) { return 'agent' }
      if (obj['CODE_RGT']) { return 'wallet' }
      if (obj['CODEICX']) { return 'entity' }
      return undefined
    },

    linkedObjectLabel (obj) {
      if (this.linkedObjectType(obj) === 'agent') { return `${obj['PRENOM']} ${obj['NOMITV']}` }
      if (this.linkedObjectType(obj) === 'wallet') { return obj['NOMPTF'] }
      if (this.linkedObjectType(obj) === 'entity') { return obj['label'] }
      return undefined
    },

    linkedObjectIcon (obj) {
      if (this.linkedObjectType(obj) === 'agent') { return { name: 'accessibility', color: 'reseau' } }
      if (this.linkedObjectType(obj) === 'wallet') { return { name: 'account_balance_wallet', color: 'reseau' } }
      if (this.linkedObjectType(obj) === 'entity') { return { name: 'account_balance_wallet', color: 'reseau' } }
      return undefined
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
