<template>
  <div class="row">
    <div class="col col-8 q-pa-md q-gutter-md">
      <CiblesTimeline :cibles="cibles" color="per" />
    </div>
  </div>
</template>

<script>
import { CiblesTimeline } from '../../components/cibles'
import { mapGetters } from 'vuex'

export default {
  name: 'CiblesTimelinePage',
  components: { CiblesTimeline },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['cibles/getFilters']) {
      store.dispatch('cibles/fetchFilters')
    }
  },

  computed: {
    ...mapGetters({
      cibles: 'cibles/getCibles',
      filters: 'cibles/getFilters'
    })
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.$q.loading.show()

        let params = {}
        this.filters.forEach(filter => { params[filter.id] = filter.value })
        this.fetchCibles(params)
      },
      deep: true
    }
  },

  mounted () {
    this.fetchCibles()
  },

  methods: {
    fetchCibles (params) {
      params = {
        ...params,
        rowsPerPage: 0
      }

      this.$q.loading.show()
      return this.$store.dispatch('cibles/fetchCibles', params)
        .then(() => { this.$q.loading.hide() })
    },

    onMenuClick (eventName, params) {
      if (eventName === 'cible-show') {
        this.$router.push({ name: 'cible-show', params })
      }
    }
  }
}
</script>

<style>
</style>
