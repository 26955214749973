<template>
  <base-card v-bind="cible" @menuClick="menuClick" :fav_candidate="true">
    <q-avatar slot='avatar' size="3rem" color="per" text-color="white" icon="icon-icons_cible" />

    <q-item-label caption>{{$formatDate(cible.date)}}</q-item-label>
    <q-item-label>
      <strong><router-link :to="{ name: 'cible-show', params: { id: cible.id } }">{{cible.label}}</router-link></strong>
    </q-item-label>

    <template slot="indicators">
      <div v-if="displayStatutSelector">
        <base-input-select :options="selectOptions" color="per" dense
          :value="{ label: value.value, value: value.value}" ref="type_id"
          @input="val => $emit('input', { id: cible.id, value: val.value})" />
      </div>

      <div v-else class="column">
        <q-icon size="1.5rem" :name="$getIcon('statut', cible.statut)" />
        <q-icon size="1.5rem" :name="$getIcon('qualification', cible.qualification)" />
      </div>
    </template>
  </base-card>
</template>

<script>
export default {
  name: 'CibleCard',
  props: {
    'cible': { type: Object, required: true },
    'value': { type: Object, default: null }
  },

  data () {
    return {
      selectOptions: [
        { label: 'Validée', value: 'VALIDE' },
        { label: 'Rejetée', value: 'REJETE' }
      ]
    }
  },

  computed: {
    displayStatutSelector () {
      return !!this.value
    }
  },

  methods: {
    menuClick ({ name, params }) {
      this.$router.push({ name, params })
    }
  }
}
</script>
